<template>
    <div class="mainContainer">
        <div v-if="getOperations.length && !isLoading" class="statisticsContainer" >
            <div class="datepicker">
                <Calendar framed />
            </div>
            <div class="diagramsConteiner">
                <div class="diagram">
                    <p>Счета:</p>
                    <PieChart
                        :options="getChartOptions"
                        :chart-data="getAccountsData"
                    />
                </div>
                <div class="diagram">
                    <p>Расходы:</p>
                    <PieChart
                        :options="getChartOptions"
                        :chart-data="getSpendingData"
                    />
                </div>
                <div class="diagram">
                    <p>Доходы:</p>
                    <PieChart
                        :options="getChartOptions"
                        :chart-data="getIncomingData"
                    />
                </div>
            </div>
            <div class="analyticsContainer">
                <Statistics :heightOffset="470" />
                <div class="analytics">
                    <p>Аналитика:</p>
                    <p class="analytics__message">
                        - продолжайте использовать приложение и в скором времени вам будет доступна аналитика, исходя из ваших расходов и доходов!
                    </p>
                </div>
            </div>
        </div>
        <h5 v-else-if="!isLoading">Мало информации для статистики!</h5>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Calendar from '@/components/Calendar';
import Statistics from '@/components/Statistics';
import PieChart from '@/components/Statistics/PieChart.js';

export default {
    name: 'graphs',
    components: {
        Statistics,
        Calendar,
        PieChart
    },
    data() {
        return {
            isLoading: true,
            backgroundColors: ['#2f7ed8', '#c42525', '#77a1e5', '#8bbc21', '#1aadce','#492970', '#f28f43', '#a6c96a', '#910000'],
        }
    },
    computed: {
        ...mapGetters([ "getOperations", "getAccounts", "getSpending", "getIncoming", "isMobileMenu" ]),
        getAccountsData() {
            let balances = [], names = [];
            this.getAccounts.forEach( account => {
                names.push(" " + account.name);
                balances.push(this.$convertToDefultCurrency(account.currency, account.balance).toFixed(2));
            });
            return {
                labels: names,
                datasets: [{
                    label: 'uah',
                    data: balances,
                    backgroundColor: [ '#F9A825', ...this.backgroundColors ],
                    hoverOffset: 10,
                    hoverBorderWidth: 4,
                    hoverBorderColor: [ '#F9A825', ...this.backgroundColors ],
                }],
            }
        },
        getSpendingData() {
            let balances = [], names = [];
            this.getSpending.forEach( spend => {
                names.push(" " + spend.name);
                balances.push(this.$convertToDefultCurrency(spend.currency, spend.balance).toFixed(2));
            });
            return {
                labels: names,
                datasets: [{
                    data: balances,
                    backgroundColor: [ '#d6d6d6', ...this.backgroundColors ],
                    hoverOffset: 10,
                    hoverBorderWidth: 4,
                    hoverBorderColor: [ '#d6d6d6', ...this.backgroundColors ],
                }],
            }
        },
        getIncomingData() {
            let balances = [], names = [];
            this.getIncoming.forEach( income => {
                names.push(" " + income.name);
                balances.push(this.$convertToDefultCurrency(income.currency, income.balance).toFixed(2));
            });
            return {
                labels: names,
                datasets: [{
                    data: balances,
                    backgroundColor: [ '#2dd3aa', ...this.backgroundColors ],
                    hoverOffset: 10,
                    hoverBorderWidth: 4,
                    hoverBorderColor: [ '#2dd3aa', ...this.backgroundColors ],
                }],
            }
        },
        getChartOptions () {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: (this.isMobileMenu) ? 'bottom' : 'right',
                }
            }
        }
    },
    async mounted() {
        if (!this.getOperations.length) {
            try {
                await this.loadCategories();
                await this.loadOperations();
                this.isLoading = false;
            } catch (err) {
                console.log(err);
            }
        } else {
            this.OPERATIONS_FILTERED([false, false]);
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions(["loadOperations","loadCategories"]),
        ...mapMutations(["OPERATIONS_FILTERED"]),
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/vars.scss';

    .mainContainer {
        display: flex;
        flex-wrap: wrap;
        padding: 35px 65px 0 65px;
    }

    h5 {
        text-align: center;
        padding: 10px;
        color: $text-regular;
    }

    .statisticsContainer {
        position: relative;
        flex: 1 0 100%;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .analyticsContainer {
        display: flex;
        align-items: flex-start;
        width: 100%;

        .analytics {
            flex: 1 0 640px;
            color: $text;
            font-size: 20px;
            padding: 10px 0px 10px 40px;

            &__message {
                font-size: 18px;
                color: $text-regular;
                margin-top: 15px;
                padding-left: 15px;
            }
        }
    }
    .diagramsConteiner {
        flex: 1 0 100%;
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(3, minmax(auto, 320px)) 200px;
        grid-auto-rows: minmax(100px, auto);
        justify-content: space-between;
        .diagram {
            position: relative;
            p {
                position: absolute;
                color: $text;
                font-size: 20px;
                padding: 10px 0;
            }
        }
    }
    .datepicker {
        position: absolute;
        right: 0;
        z-index: 999;
    }

    @media screen and (max-width: 1750px) {
        .mainContainer {
            padding: 25px 25px 0 25px;
        }
    }

    @media screen and (max-width: 1440px) {
        .analyticsContainer {
            .analytics {
                flex: 1 0 400px;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .analyticsContainer {
            flex-wrap: wrap;
            .analytics {
                flex: 1 0 100%;
                padding-left: 0;
            }
        }
        .diagramsConteiner {
            flex: 1 0 100%;
            max-width: 100%;
            display: grid;
            grid-template-columns: repeat(3, minmax(auto, 320px));
            grid-auto-rows: minmax(100px, auto);
        }
        .datepicker {
            display: none;
        }
    }

    @media screen and (max-width: 768px) {
        .mainContainer {
            padding: 25px 15px 0 15px;
        }
        .diagramsConteiner {
            grid-template-columns: minmax(auto, 280px);
            justify-content: center;
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 610px) {
        h5 {
            font-size: 16px;
            padding: 0;
        }
    }
</style>
